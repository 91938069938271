import { Color, PinataColor } from '@pafcloud/style';

export const Colors = {
  Text: PinataColor.White,
  DepositQuickButtons: PinataColor.Alternate,
  DepositQuickButtonsHover: PinataColor.AlternateHighlight,
  Border: `1px solid ${PinataColor.AquaDark}`,
  Background: `linear-gradient(180deg, ${Color.Primitive.Accent}, ${Color.Primitive.AccentTint})`,
  SidebarContent: undefined,
  SidebarTopBackground: 'transparent',
  SidebarOverlay: 'rgba(0,0,0,0.5)',
  EmphasizedLink: Color.Primitive.Secondary,
};
