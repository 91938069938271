import { PinataColor } from '@pafcloud/style';

export const Colors = {
  Text: PinataColor.AquaDark,
  MessageSeenText: PinataColor.NeutralDark,
  PaginationText: PinataColor.AquaDark,

  BorderColor: PinataColor.AquaTransparent50,

  Highlight: `${PinataColor.AquaDark}0D`,
  HighlightBorder: PinataColor.AquaDark,
};
