import styled from '@emotion/styled';
import type { TouchEvent } from 'react';
import { forwardRef, useRef } from 'react';
import { ZIndex, DynamicValues, FontTextSize, Color } from '@pafcloud/style';

import { Icon, InternalLink, Logo, NoStyleButton } from '@pafcloud/base-components';
import { useTranslation } from '@pafcloud/i18n';
import { MainMenuItemIcon } from '../MainMenuIcons';
import { MainMenuItem } from '../MainMenuItem';
import { SidebarColors } from './sidebar-colors';
import type { SidebarProps } from './Sidebar';

type NavProps = {
  isOpen: boolean;
  'data-open': boolean;
  onTouchMove: (event: TouchEvent) => void;
  onTouchStart: (event: TouchEvent) => void;
};

const Nav = styled.nav<NavProps>(({ isOpen }) => ({
  position: 'fixed',
  top: DynamicValues.GamePauseHeight,
  bottom: 0,
  left: 0,

  display: 'flex',
  flexDirection: 'column',

  width: 'min(320px, calc(100vw - var(--full-width-margin)))',
  overflowY: 'auto',
  zIndex: ZIndex.MainMenu,
  transition: `transform 0.300s 0s, box-shadow 0.2s 0.1s, filter 0.2s 0.15s ease-out`,
  transform: isOpen ? undefined : 'translateX(-100%)',
  background: SidebarColors.Background,

  filter: isOpen ? undefined : 'brightness(0.6)',
  overscrollBehavior: 'contain',

  '::-webkit-scrollbar': {
    width: 0,
  },

  // Enable overscroll behavior
  '::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    height: 10,
    width: 10,
    marginBottom: -1,
  },
}));

const NavContent = styled.div({
  '--scale-timing': '0.1s',
  transition: 'transform var(--scale-timing) 0.1s ease-in-out ',
  transform: 'scale(0.9)',

  '[data-open="true"] &': {
    '--scale-timing': '0.2s',
    transform: 'scale(1)',
  },

  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  width: '100%',
  paddingBottom: 40,

  '> ul': {
    margin: '0 12px',

    [`${MainMenuItem}`]: {
      minHeight: 48,
      marginBottom: 8,
      borderRadius: 4,
      isolation: 'isolate',
      transition: 'font-variation-settings 0.2s ease-out',
      fontSize: FontTextSize.Huge,
    },

    [`${MainMenuItemIcon}`]: {
      fontSize: '0.8em',
      marginRight: 16,
    },
  },
});

const CloseButton = styled(NoStyleButton)({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 48,
  height: 48,
  borderRadius: `0 0 0 ${Color.CornerRadius.Base}`,
  color: Color.Primitive.PrimaryContrast,
  textAlign: 'center',

  ':hover, :focus': {
    background: Color.Primitive.PrimaryContrast,
    color: Color.Primitive.Primary,
    outline: 'transparent',
  },

  '> span': {
    height: '100%',
  },

  svg: {
    height: 20,
    transform: 'scale(-1) translateX(0.25px)',
    stroke: 'currentcolor',
  },
});

const SidebarHeader = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '40px 24px',
  marginBottom: 24,

  '::before': {
    backgroundColor: 'rgba(0,0,0,0.2)',
    backgroundImage: `url('/static/images/pinata/plink-wave.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '65%',
    backgroundPosition: '-50px 42px',
    content: '""',
    clipPath: 'polygon(0 0, 100% 0, 100% 85%, 0 100%)',

    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    height: '100%',
  },
});

const SidebarLogo = styled(Logo)({
  height: 90,
  filter: 'drop-shadow(2px 4px 0 rgba(0,0,0,0.2))',
  marginLeft: 8, // offset to visually align
});

export const Sidebar = forwardRef<HTMLElement, SidebarProps>(({ onClose, isOpen, children }, ref) => {
  const { t } = useTranslation('header');
  const touchClientXRef = useRef<number | null>(null);

  const handleTouchStart = (event: TouchEvent) => {
    touchClientXRef.current = event.targetTouches[0].clientX;
  };

  const handleTouchMove = (event: TouchEvent) => {
    if (touchClientXRef.current && isOpen) {
      const touchDistance = touchClientXRef.current - event.targetTouches[0].clientX;
      const touchOffset = 80;

      if (touchDistance > touchOffset) {
        onClose();
      }
    }
  };

  return (
    <Nav
      isOpen={isOpen}
      ref={ref}
      data-testid="main-menu"
      data-open={isOpen}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <NavContent>
        <SidebarHeader>
          <InternalLink href="/" aria-label={t('logo.aria-label')}>
            <SidebarLogo />
          </InternalLink>
          <CloseButton onClick={onClose}>
            <Icon name="closeThinRounded" />
          </CloseButton>
        </SidebarHeader>
        {children}
      </NavContent>
    </Nav>
  );
});

Sidebar.displayName = 'Sidebar';
