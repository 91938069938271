import { PinataColor } from '@pafcloud/style';

export const Colors = {
  Text: PinataColor.White,
  TextHover: PinataColor.Alternate,
  TextActive: PinataColor.Alternate,
  TextShadow: `3px 3px 0 ${PinataColor.Shadow}`,

  Icon: undefined,
  IconActive: undefined,

  Background: undefined,
  BackgroundHover: undefined,
  BackgroundActive: undefined,

  ItemDivider: undefined,
  ItemActiveShadow: undefined,

  SubMenuBackground: undefined,
  SubMenuRuler: 'rgba(255,255,255,0.2)',
};

export const FontStyle = {
  TextTransform: 'uppercase',
  Weight: 900,
  WeightHover: 900,
  WeightActive: 900,
} as const;
